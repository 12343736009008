<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">{{ translations.tcAddEditAnnualMembershipDinnerSpeakers }}</h1>
    </page-header>
    <page-body class>
      <section class="section-1 bg-block mb-4">
        <h1 class="sm mb-4">{{ translations.tcAddEditAnnualMembershipDinnerSpeakers }}</h1>
        <div class="body">
          <b-form>
            <div class="row">
              <div class="col-8">
                <b-form-group class="form-element">
                  <div class="date-select-1 mb-1">
                    <label class="font-style-1">{{ translations.tcDate }} *</label>
                  </div>
                  <div class="date-select-1 mb-3">
                    <b-form-datepicker id="speakerDate" v-model="data.start_date" class="mb-2 contact_date"
                      :locale="prefCulture" :label-help="translations.tcCalHelp" :max="maxDate"
                      :placeholder="translations.tcDatePickerPlaceholder"
                      :label-no-date-selected="translations.tcDatePickerPlaceholder" :show-decade-nav="showDecadeNav">
                      <template #button-content style="padding: 12px">
                        <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                      </template>
                    </b-form-datepicker>
                  </div>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <b-form-group class="form-element">
                  <div class="date-select-1 mb-1">
                    <label class="font-style-1">{{ translations.tcPresenter }} *</label>
                  </div>
                  <div class="g-row mb-0 mb-sm-3">
                    <div class="g-col-1 d-flex mb-3 mb-sm-0">
                      <b-form-select :title="translations.tcPresenter" v-model="data.acr_ind_key"
                        :options="presenterOption" class="g-select flex-0 mr-3 mb-3" required></b-form-select>
                      <b-button :name="`findSpeakerButton`" v-if="shouldEdit" variant="primary"
                        class="flex-1 btn-pl-xsm" @click="showModal($event, 'personSearchModal', 'presenter')">{{
                          translations.tcFind
                        }}</b-button>
                      <template slot="first">
                        <option :value="null" disabled>{{ translations.tcSelect }}</option>
                      </template>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>
          </b-form>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button variant="primary" @click="handleSaveClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
              translations.tcSave
            }}</b-button>
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">{{
              translations.tcCancel
            }}</b-button>
          </div>
        </div>
      </section>
    </page-body>
    <PersonSearch :cmp_org_key="this.churchCampKey" @selectionMade="applyFoundPerson($event)"></PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'upsert_dinner_speaker',
  mixins: [translationMixin],
  data() {
    return {
      showDecadeNav: true,
      hideHeader: true,
      translations: {},
      findButton: '',
      selectChurch: [],
      searchedContact: [],
      contactOption: [],
      presenterOption: [],
      shouldEdit: false,
      currentSelection: '',
      maxDate: new Date(),
      date: null,
      data: {
        ixo_key: null,
        ixa_key: null,
        acr_ind_key: '',
        org_key: '',
        user_ind_key: '',
        start_date: null,
      },
    }
  },
  methods: {
    ...mapActions({
      getVideoVisitDetails: 'card/retrieveVideoVisitDetails',
      retrieveMeetingTypes: 'card/retrieveMeetingTypes',
      setLoadingStatus: 'menu/setLoadingStatus',
      upsertDinnerSpeakers: 'directory/upsertDinnerSpeakers',
      upsertVideoVisit: 'card/upsertVideoVisit',
    }),
    showModal(e, modalName, selection) {
      this.currentSelection = selection
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    applyFoundPerson($event) {
      this.data.acr_ind_key = $event.ind_key
      let option = { value: $event.ind_key, text: $event.name_text }
      this.presenterOption.push(option)
    },
    validate() {
      if (this.data.start_date == null) {
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: this.translations.tcYourDateIsEmpty, //'Your date is empty',
        })
        return false
      }
      if (this.data.acr_ind_key === '') {
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: this.translations.tcPleaseSelectASpeaker, //'Please select a speaker',
        })
        return false
      }
      if (!this.data.org_key || !this.data.user_ind_key) {
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: this.translations.tcErrorMessage, //'Could not proceed, kindly reload the page or try again',
        })
        return false
      }
      return true
    },
    handleSaveClick() {
      if (!this.validate()) {
        return
      }
      this.submitRequest()
    },
    handleCancelClick() {
      this.$router.push({ name: this.isState ? 'membership-dinner-speakers' : 'membership-dinner-speakers-country' })
      return
    },
    async submitRequest() {
      try {
        this.setLoadingStatus(true)
        let response = await this.upsertDinnerSpeakers(this.data)
        if (response != '') {
          this.handleCancelClick()
          return
        }
      } catch (error) {
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: this.translations.tcAdminErrorMessage,
          //'There was a problem in updating this speaker, Refresh and try again or contact your admin',
        })
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async page_load() {
      this.presenterOption = await this.dinnerSpeakers.map(ds => { return { text: ds.vin_complete_name, value: ds.ind_key } })
      if (!!this.item && !!this.item.item && !!this.item.item.ixo_key) {
        this.shouldEdit = false
        this.data.ixo_key = this.item.item.ixo_key
        this.data.ixa_key = this.item.item.ixa_key
        this.data.start_date = this.item.item.qualified_date
        this.applyFoundPerson({ ind_key: this.item.item.vin_ind_key, name_text: this.item.item.sort_name })
      } else {
        this.shouldEdit = true
      }
      this.data.user_ind_key = this.userId
      this.data.org_key = this.userStateKey
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([await this.page_load(), await this.getViewTranslations('buttons')])
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      churchCampKey: 'user/userCampKey',
      dinnerSpeakers: 'directory/dinnerSpeakers',
      item: 'directory/speakerObject',
      meetingTypes: 'card/getMeetingTypes',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userStateKey: 'user/userStateKey',
      videoVisitDetails: 'card/getVideoVisitDetails',
    }),
    optionMeetingTypes() {
      return this.meetingTypes.map((type) => {
        return { value: type.mts_key, text: type.description }
      })
    },
    isState() {
      return this.$route.path.toLowerCase().includes('country') ? false : true
    },
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    PersonSearch: PersonSearch,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  width: 55%;

  @include breakpoint(sm) {
    width: 100%;
  }

  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
